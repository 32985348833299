// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SBlVacHxoN32SzJOypab{min-width:190px !important}`, "",{"version":3,"sources":["webpack://./pages/user/user.module.scss"],"names":[],"mappings":"AAAC,sBACG,0BAAA","sourcesContent":[" .login_dropdown_container {\n    min-width: 190px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login_dropdown_container": `SBlVacHxoN32SzJOypab`
};
export default ___CSS_LOADER_EXPORT___;
