// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-select-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .chip-item {
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 5px;
  }
  
  .text-input {
    width: 100%;
    display: flex; /* Added */
    flex-direction: row; /* Added */
    align-items: center; /* Added */
    gap: 10px; /* Added */
  }
  `, "",{"version":3,"sources":["webpack://./components/multiSelectWithInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,aAAa;IACb,eAAe;IACf,QAAQ;EACV;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa,EAAE,UAAU;IACzB,mBAAmB,EAAE,UAAU;IAC/B,mBAAmB,EAAE,UAAU;IAC/B,SAAS,EAAE,UAAU;EACvB","sourcesContent":[".multi-select-form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .chip-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 5px;\n  }\n  \n  .chip-item {\n    background-color: #f0f0f0;\n    padding: 5px;\n    border-radius: 5px;\n  }\n  \n  .text-input {\n    width: 100%;\n    display: flex; /* Added */\n    flex-direction: row; /* Added */\n    align-items: center; /* Added */\n    gap: 10px; /* Added */\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
