// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .image-preview {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
  }
  
  .preview-item {
    position: relative;
  }
  
  .preview-image {
    width: 115px;
    height: 85px;
    object-fit: cover;
    border-radius: 5px;
}
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
  }
  `, "",{"version":3,"sources":["webpack://./components/singleImageUploader.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;EACE;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".drop-area {\n    border: 2px dashed #ccc;\n    padding: 20px;\n    text-align: left;\n    cursor: pointer;\n    margin-top: 10px;\n  }\n  \n  .image-preview {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    margin-top: 10px;\n  }\n  \n  .preview-item {\n    position: relative;\n  }\n  \n  .preview-image {\n    width: 115px;\n    height: 85px;\n    object-fit: cover;\n    border-radius: 5px;\n}\n  .delete-button {\n    position: absolute;\n    top: 5px;\n    right: 5px;\n    color: #fff;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 5px;\n    border-radius: 50%;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
