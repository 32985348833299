// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wdj6uAoxz77L_Y8mp5TQ .smnWC9FGulkyNVKY9kdW{text-decoration:none;color:#000;text-transform:capitalize}.wdj6uAoxz77L_Y8mp5TQ .smnWC9FGulkyNVKY9kdW:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./components/Breadcrums/Breadcrums.module.scss"],"names":[],"mappings":"AAGE,4CACE,oBAAA,CACA,UAAA,CACA,yBAAA,CAEA,kDACE,yBAAA","sourcesContent":["// @import '../../global.scss';\n\n.container {\n  .link {\n    text-decoration: none;\n    color: black;\n    text-transform: capitalize;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wdj6uAoxz77L_Y8mp5TQ`,
	"link": `smnWC9FGulkyNVKY9kdW`
};
export default ___CSS_LOADER_EXPORT___;
